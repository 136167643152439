<template>
  <v-app light>
    <v-content>
      <section>
        <v-layout column wrap class="my-5" align-center>
          <v-flex xs12 sm4 class="my-3">
            <div class="text-xs-center">
              <h2 class="headline">Paket Usaha</h2>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-boba.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA BOBA</b> <br />
                      Rp3,700,000
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="blue"> Lihat detail </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-capcin.jpg" alt="" />
                    </v-card-text>

                    <v-card-text>
                      <b> PAKET USAHA CAPCIN</b> <br />
                      Rp3,700,000
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="blue"> Lihat detail </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-chicken.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA CAPCIN</b> <br />
                      Rp3,700,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <v-btn text color="blue"> Lihat detail </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-bakso.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA BAKSO</b> <br />
                      Rp3,800,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <v-btn text color="blue"> Lihat detail </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-banana.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA BANANA NOUGAT</b> <br />
                      Rp3,800,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <v-btn text color="blue"> Lihat detail </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>

                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-burger.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA BURGER HOTDOG</b> <br />
                      Rp3,800,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <v-btn text color="blue"> Lihat detail </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <vue-whatsapp-widget phoneNumber="+1111111111" />
      </section>
      <section>
        <v-footer dark padless>
          <v-card
            flat
            tile
            width="100%"
            class="primary white--text text-center"
          >
            <v-divider></v-divider>

            <v-card-text class="white--text">
              Copyright 2023 - {{ new Date().getFullYear() }}
              <a class="white--text" href="">FNB Indonesia</a>
            </v-card-text>
          </v-card>
        </v-footer>
      </section>
    </v-content>
  </v-app>
</template>
    
    <script>
import VueWhatsappWidget from "vue-whatsapp-widget";
export default {
  name: "Package",
  components: {
    VueWhatsappWidget,
  },
  data: function () {
    return {
      title: "Package",
      imageLink: {
        main: "https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyZWV0JTIwY2FmZXxlbnwwfHwwfHw%3D&w=1000&q=80",
        sub_main:
          "https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyZWV0JTIwY2FmZXxlbnwwfHwwfHw%3D&w=1000&q=80",
        logo: "https://png.pngtree.com/template/20191203/ourmid/pngtree-coffee-logo-design-vector-image_337940.jpg",
      },
    };
  },
};
</script>