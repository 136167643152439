import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import Home from './components/Home.vue'
import About from './components/About.vue'
import Detail from './components/Detail.vue'
import Faq from './components/Faq.vue'
import Package from './components/Package.vue'
Vue.config.productionTip = false
Vue.use(VueRouter)
const router = new VueRouter({
  routes:[
    {
      path:'/',
      component:Home
    },
    {
      path:'/about',
      component:About
    },
    {
      path:'/detail-booth',
      component:Detail
    },
    {
      path:'/faq',
      component:Faq
    },
    {
      path:'/package',
      component:Package
    }
  ]
})
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
