<template>
  <section>
    <v-row class="mt-4">
      <v-col sm="12" xs="12" lg="12">
        <v-card class="mb-2" tile="">
          <v-row align="start">
            <v-col class="shrink">
              <v-img
                src="/images/paket-capcin.jpg"
                max-width="400"
                class="ml-3"
              ></v-img>
            </v-col>
            <v-col>
              <v-card-title
                >PAKET USAHA CAPCIN CAPPUCINO CINCAU BOOTH PERALATAN ALAT BAHAN
                LENGKAP</v-card-title
              >
              <v-card-subtitle>Rp3,700,000</v-card-subtitle>
              <v-card-text>
                Capcin Cappucino Cincau Paket Lengkap Rp3.700.000 <br />
                Tanpa Booth Rp2.700.000 <br />
                Paket Lengkap Tanpa Cup Sealer Rp3.000.000 <br />
                Tanpa Booth Tanpa Cup Sealer Rp2.000.000
              </v-card-text>
              <v-card-action>
                <v-btn class="ma-2" color="success" dark>
                  Pesan via whatsapp
                  <v-icon dark right> mdi-whatsapp </v-icon>
                </v-btn>
              </v-card-action>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-card>
        <v-toolbar flat color="blue" dark>
          <v-toolbar-title>Deskripsi</v-toolbar-title>
        </v-toolbar>
        <v-tabs vertical>
          <v-tab>
            <v-icon left> mdi-chevron-right </v-icon>
          </v-tab>

          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  PELUANG USAHA MODAL 3,7jt CAPPUCINO CINCAU SMS/WA : 0811 28
                  14045 <br />

                  1. Bisa COD – Bayar di Tempat 2. Gratis Ongkir Jabodetabek 3.
                  Lengkap dengan Booth, Peralatan dan Bahan Baku 4. Sistem Jual
                  Putus, Tanpa Bagi Hasil Royalti & Franchise Fee . 100%
                  Keuntungan Milik Anda!
                  <br />
                  <b>PAKET USAHA CAPPUCINO CINCAU</b>
                  <br />
                  Cappucino cincau atau capcin adalah salah satu minuman jalanan
                  di Indonesia. Capcin dihasilkan dari mencampurkan es kapucino
                  dengan serutan cincau. Minuman ini sangat populer dan
                  digandrungi masyarakat Indonesia sekitar tahun 2013.
                  Gerai-gerai penjual capcin dapat dengan mudah dijumpai di
                  banyak tempat.
                  <br />
                  Dengan harga yang relatif murah, es kapucino cincau menyasar
                  pembeli dari kalangan menengah ke bawah.dengan campuran cincau
                  yang kenyal dan lembut ,tak heran es ini banyak di cari
                  sebagai minuman segar dan manis .
                  <br />
                  Adapun franchise cappucinno cincau di antaranya Bloom
                  Cappucino cincau , Capcin caplin ,Kohwah dan lain lain yang
                  diperkirakan mematok harga franchise nya sekitar 5 – 7 juta .
                  <br />
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-row>
    <section>
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="headline">Paket Terkait</h2>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-text class="text-xs-center">
                    <img src="/images/paket-boba.jpg" alt="" />
                  </v-card-text>
                  <v-card-text>
                    <b> PAKET USAHA BOBA</b> <br />
                    Rp3,700,000
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="blue"> Lihat detail </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-text class="text-xs-center">
                    <img src="/images/paket-capcin.jpg" alt="" />
                  </v-card-text>

                  <v-card-text>
                    <b> PAKET USAHA CAPCIN</b> <br />
                    Rp3,700,000
                  </v-card-text>
                  <v-card-actions>
                    <v-btn text color="blue"> Lihat detail </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card class="elevation-0 transparent">
                  <v-card-text class="text-xs-center">
                    <img src="/images/paket-chicken.jpg" alt="" />
                  </v-card-text>
                  <v-card-text>
                    <b> PAKET USAHA CAPCIN</b> <br />
                    Rp3,700,000
                  </v-card-text>
                  <v-card-actions>
                    <router-link to="/detail-booth" text color="blue">
                      <v-btn text color="blue"> Lihat detail </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>
    <vue-whatsapp-widget phoneNumber="+1111111111" />
    <v-footer dark padless>
      <v-card flat tile width="100%" class="primary white--text text-center">
        <v-divider></v-divider>

        <v-card-text class="white--text">
          Copyright 2023 - {{ new Date().getFullYear() }}
          <a class="white--text" href="">FNB Indonesia</a>
        </v-card-text>
      </v-card>
    </v-footer>
  </section>
</template>
<script>
import VueWhatsappWidget from "vue-whatsapp-widget";
export default {
  name: "Detail",
  components: {
    VueWhatsappWidget,
  },
};
</script>