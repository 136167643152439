<template>
  <nav>
    <v-toolbar class="blue">
      <v-app-bar-nav-icon
        class="white--text"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <!-- dropdown menu -->
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <!-- <v-btn text slot="activator"> -->
          <v-btn text v-on="on" class="white--text">
            <v-icon left>expand_more</v-icon>
            <span>Menu</span>
          </v-btn>
        </template>
        <v-list>
          <!-- v-list-tile is changed to v-list-item -->
          <v-list-item
            v-for="link in links"
            :key="link.text"
            router
            :to="link.route"
          >
            <v-list-item-title>{{ link.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app class="primary">
      <v-list>
        <!-- v-list-tile was replaced to v-list-item at Vuetify 2.0 -->
        <v-list-item
          v-for="link in links"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon class="white--text">{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
  
  
<script>
export default {
  data: () => ({
    drawer: false,
    links: [
      { icon: "home", text: "Home", route: "/" },
      { icon: "contacts", text: "Tentang Kami", route: "/about" },
      { icon: "contacts", text: "Pertanyaan Umum", route: "/faq" },
      { icon: "contacts", text: "Paket Usaha", route: "/package" },
    ],
  }),
};
</script>