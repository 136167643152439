<template>
  <v-content>
    <v-section>
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm4 class="my-3">
          <div class="text-xs-center">
            <h2 class="headline">Pertanyaan Umum</h2>
          </div>
        </v-flex>
      </v-layout>
    </v-section>
    <v-section>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Bagaimana Syarat dan ketentuan untuk bergabung menjadi mitra
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Kami membuka kesempatan bagi semua orang yang ingin memulai bisnis /
            usahanya, tanpa syarat dan ketentuan tertentu. Setiap orang dimana
            saja bisa menjadi mitra kami! Silahkan hubungi kami untuk berdiskusi
            mengenai paket usaha yang cocok dengan Anda! Kami melayani
            pengiriman gratis untuk JABODETABEK dan bekerjasama dengan Indah
            Cargo dan JNE Trucking untuk melayani Anda di seluruh Indonesia!
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Apa saja yang sudah termasuk dalam paket usaha
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Setiap paket usaha yang kami sediakan sudah lengkap dan siap Anda
            gunakan untuk memulai bisnis/usaha Anda, meliputi: <br />
            <ul>
              <li>
                Booth Portable (lockdown) yang mudah dibongkar pasang (tanpa
                membutuhkan obeng)
              </li>
              <li>X-Banner sebagai media promosi</li>
              <li>
                Seluruh peralatan dan bahan baku yang dibutuhkan untuk bisnis /
                usaha tersebut
              </li>
              <li>Kemasan / packaging penjualan</li>
              <li>
                Pelatihan dan bimbingan penjualan dan pengolahan bahan baku
              </li>
            </ul>
            <br />
            Seluruhnya sudah kami siapkan untuk Anda dengan modal yang kecil
            untuk menjamin Anda balik modal dalam waktu yang singkat! Anda dapat
            berkonsultasi setiap saat melalui layanan Whatsapp khusus Mitra yang
            sudah bergabung, mengenai kendala, perkembangan dan kebutuhan bisnis
            Anda.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Bagaimana jika bahan baku/kemasa habis
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Kami menyediakan layanan Whatapp khusus mitra yang dapat melayani
            pengiriman setiap hari (termasuk hari libur) untuk memenuhi
            kebutuhan bahan baku dan kemasan dengan harga yang bersaing. <br />
            Namun, kami juga memberikan kebebasan untuk memesan bahan baku dari
            tempat lain sesuai dengan preferensi dan kebutuhan Anda.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Berapa lama pengiriman paket usaha yang kami pesan
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            Penjadwalan pengiriman membutuhkan waktu paling lambat 3-4 hari
            sesuai dengan jumlah pesanan yang masuk <br />
            Mitra yang melalukan pendaftaran terlebih dahulu akan kami
            prioritaskan untuk dapat menerima paket usaha lebih cepat dan dapat
            segera memulai usaha / bisnisnya.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-section>
    <vue-whatsapp-widget phoneNumber="+1111111111" />
  </v-content>
</template>
<script>
import VueWhatsappWidget from "vue-whatsapp-widget";
export default {
  name: "Faq",
  components: {
    VueWhatsappWidget,
  },
};
</script>