<template>
  <v-app light>
    <v-content>
      <section>
        <v-layout column wrap class="my-5" align-center>
          <v-flex xs12 sm4 class="my-3">
            <div class="text-xs-center">
              <h2 class="headline">Paket Usaha Angkringan</h2>
            </div>
          </v-flex>
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout row wrap align-center>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-boba.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA BOBA</b> <br />
                      Rp3,700,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <v-btn text color="blue"> Lihat detail </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-capcin.jpg" alt="" />
                    </v-card-text>

                    <v-card-text>
                      <b> PAKET USAHA CAPCIN</b> <br />
                      Rp3,700,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <v-btn text color="blue"> Lihat detail </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card class="elevation-0 transparent">
                    <v-card-text class="text-xs-center">
                      <img src="/images/paket-chicken.jpg" alt="" />
                    </v-card-text>
                    <v-card-text>
                      <b> PAKET USAHA CAPCIN</b> <br />
                      Rp3,700,000
                    </v-card-text>
                    <v-card-actions>
                      <router-link to="/detail-booth" text color="blue">
                        <router-link to="/detail-booth" text color="blue">
                          <v-btn text color="blue"> Lihat detail </v-btn>
                        </router-link>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>

      <section>
        <v-parallax :src="imageLink.main" height="380">
          <v-layout column align-center justify-center>
            <div class="headline white--text mb-3 text-xs-center">
              Peluang usaha dengan modal cuman 3jutaan
            </div>
            <router-link to="/package" class="blue mt-5" dark large>
              <v-btn text color="white"> Lihat Paket Usaha </v-btn>
            </router-link>
          </v-layout>
        </v-parallax>
      </section>

      <section>
        <v-container grid-list-xl>
          <v-layout row wrap justify-center class="my-5">
            <v-flex xs12 sm4>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Info Perusahaan</div>
                </v-card-title>
                <v-card-text>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Porro quo sunt recusandae ea magni ipsa dolores quam, iusto
                  impedit fuga! Commodi iste in eius, minima voluptatum nam?
                  Neque, esse expedita.
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 sm4 offset-sm1>
              <v-card class="elevation-0 transparent">
                <v-card-title primary-title class="layout justify-center">
                  <div class="headline">Kantor Kami</div>
                </v-card-title>
                <v-card-text>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Porro quo sunt recusandae ea magni ipsa dolores quam, iusto
                  impedit fuga! Commodi iste in eius, minima voluptatum nam?
                  Neque, esse expedita.
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </section>

      <section>
        <v-container>
          <v-layout>
            <v-flex xs12 class="text-xs-center">
              <img height="200px" :src="imageLink.logo" />
            </v-flex>
          </v-layout>
        </v-container>
        <vue-whatsapp-widget phoneNumber="+1111111111" />
      </section>
      <section>
        <v-footer dark padless>
          <v-card
            flat
            tile
            width="100%"
            class="primary white--text text-center"
          >
            <v-divider></v-divider>

            <v-card-text class="white--text">
              Copyright 2023 - {{ new Date().getFullYear() }}
              <a class="white--text" href="">FNB Indonesia</a>
            </v-card-text>
          </v-card>
        </v-footer>
      </section>
    </v-content>
  </v-app>
</template>
  
  <script>
import VueWhatsappWidget from "vue-whatsapp-widget";
export default {
  name: "Home",
  components: {
    VueWhatsappWidget,
  },
  data: function () {
    return {
      title: "Endorfine",
      imageLink: {
        main: "https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyZWV0JTIwY2FmZXxlbnwwfHwwfHw%3D&w=1000&q=80",
        sub_main:
          "https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3RyZWV0JTIwY2FmZXxlbnwwfHwwfHw%3D&w=1000&q=80",
        logo: "https://png.pngtree.com/template/20191203/ourmid/pngtree-coffee-logo-design-vector-image_337940.jpg",
      },
      email: "",
      emailRules: [
        (v) => {
          return !!v || "E-mail is required";
        },
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      subscribed: false,
    };
  },
  methods: {
    subscribe: function () {
      this.subscribed = !this.subscribed;
    },
  },

  computed: {
    imgHeight: function () {
      var offset = 320;
      console.log("new image height is " + (this.pageHeight - offset));
      return this.pageHeight - offset;
    },
  },

  mounted: function () {
    this.calculateHeight();
  },
};
</script>